import { useState, useEffect, useCallback } from 'react';

// same as in public/index.html
const EVENT = 'supportChatVisibilityChange';

// controlled wrapper component for custom "chat with us" button
const useSupportChat = (chat) => {
  const [isOpen, setIsOpen] = useState(chat?.isOpened);
  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!chat) return undefined;

    setIsOpen(chat.isOpened);

    const handleVisibilityChange = (event) => {
      setIsOpen(event.detail.visible);
    };

    window.addEventListener(EVENT, handleVisibilityChange);

    return () => window.removeEventListener(EVENT, handleVisibilityChange);
  }, [chat]);

  useEffect(() => {
    if (!chat) return;

    isOpen ? chat.openWindow() : chat.closeWindow();
  }, [isOpen, chat]);

  return { isLoaded: !!chat, isOpen, toggle };
};

export default useSupportChat;
