import React from 'react';
import { useIdentity } from '../../../contexts/IdentityContext';

const whiteList = [
  'vilinskyy7@gmail.com', // - designer
  'alex.orlov7@gmail.com', // - front-end
];

function AxisUserGuard({ children, fallback }) {
  const { isAxisUser, user } = useIdentity();

  if (!isAxisUser && !whiteList.includes(user.email))
    return fallback ? <>{fallback}</> : null;

  return children;
}

export default AxisUserGuard;
