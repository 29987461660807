import React from 'react';

import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import SavedSearchs from '../../../icons/SavedSearchs';
import Bookmark from '../../../icons/Bookmark';
import Connection from '../../../icons/Connection';
import Compliant from '../../../icons/Compliant';
import { useIdentity } from '../../../contexts/IdentityContext';
import AxisFullLogo from '../../../icons/AxisFullLogo';

import css from './SideNav.module.css';
import useNavigation from '../../hooks/useNavigation';
import useSupportChat from '../../hooks/useSupportChat';
import Notifications from '../../../icons/NotificationBell';
import NotificationCount from './NotificationCount/NotificationCount';
import { ROUTES } from '../../shared/routes';
import NewExplore from '../../../icons/NewExplore';
import NotesOutline from '../../../icons/NotesOutline';
import NewFeed from '../../../icons/NewFeed';
import Support from '../../../icons/Support';

function SideNav() {
  const { user } = useIdentity();
  const { replaceParams, params } = useNavigation();
  const chat = useSupportChat(window.Atlas?.chat);

  const toggleLeftPanel = (panel) => {
    const paramName = 'leftPanel';
    const leftPanelOpened = params.get(paramName);
    const isSamePanel = panel === leftPanelOpened;
    params.delete('isFilterOpen');

    if (isSamePanel) {
      params.delete(paramName, panel);
    } else {
      params.set(paramName, panel);
    }
    replaceParams(params);
  };

  const { pathname } = window.location;
  const isExploreActive = pathname.includes('/search');
  const isFeedActive = pathname.includes('/feed');
  const isSavedSearchOpen = params.get('leftPanel') === 'saved-searches';
  const isBookmarkOpen = params.get('leftPanel') === 'bookmarks';
  const isNotesOpen = params.get('leftPanel') === 'notes';
  const isConnectionsOpen = params.get('leftPanel') === 'connections';
  const isCompliantOpen = params.get('leftPanel') === 'compliance';
  const isNotificationOpen = params.get('leftPanel') === 'notifications';

  return (
    <div className={css.main}>
      <Link to="/" className={css.link}>
        <AxisFullLogo className={css.logo} />
      </Link>

      <Link
        to={`${ROUTES.ADV_SEARCH}?isFilterOpen=true`}
        className={css.explore}
        data-active={isExploreActive}
      >
        <NewExplore />
        <h3 className={css.itemTitle}>Explore</h3>
      </Link>

      <Link to={ROUTES.FEED} className={css.explore} data-active={isFeedActive}>
        <NewFeed />
        <h3 className={css.itemTitle}>Feed</h3>
      </Link>

      <div className={css.divider} />

      <SideNavButton
        isDock
        title="Searches"
        icon={<SavedSearchs />}
        isActive={isSavedSearchOpen}
        data-cy="toggle-saved-searches"
        onClick={() => toggleLeftPanel('saved-searches')}
      />

      <SideNavButton
        isDock
        title="Bookmarks"
        icon={<Bookmark />}
        isActive={isBookmarkOpen}
        data-cy="toggle-bookmarks"
        onClick={() => toggleLeftPanel('bookmarks')}
      />

      <SideNavButton
        isDock
        title="Notes"
        icon={<NotesOutline />}
        isActive={isNotesOpen}
        data-cy="toggle-notes"
        onClick={() => toggleLeftPanel('notes')}
      />

      <SideNavButton
        isDock
        title="Contacts"
        icon={<Connection />}
        isActive={isConnectionsOpen}
        data-cy="toggle-connections"
        onClick={() => toggleLeftPanel('connections')}
      />

      <SideNavButton
        isDock
        title="Compliance"
        icon={<Compliant />}
        isActive={isCompliantOpen}
        data-cy="toggle-compliance"
        onClick={() => toggleLeftPanel('compliance')}
      />

      <div className={css.bottomIcons}>
        <SideNavButton
          isDock
          title="Notifications"
          icon={<Notifications />}
          isActive={isNotificationOpen}
          data-cy="toggle-notifications"
          onClick={() => toggleLeftPanel('notifications')}
        >
          <NotificationCount />
        </SideNavButton>

        {chat.isLoaded && (
          <SideNavButton
            isDock
            title="Chat with us"
            icon={<Support />}
            isActive={chat.isOpen}
            data-cy="toggle-support-chat"
            onClick={() => chat.toggle()}
          />
        )}

        <div className={css.halfDivider} />
        <Avatar user={user} />
      </div>
    </div>
  );
}

export default SideNav;

function SideNavButton({
  routerLink = null,
  icon,
  title,
  isActive,
  onClick,
  isDock,
  children,
}) {
  const Wrapper = routerLink ? Link : 'div';

  return (
    <Wrapper
      to={routerLink}
      className={css.wrapper}
      data-active={isActive}
      data-dock={isDock}
      onClick={onClick}
    >
      <div className={css.sideButtonIcon}>{icon}</div>
      <h3>{title}</h3>
      {children}
    </Wrapper>
  );
}
