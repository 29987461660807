import React from 'react';
import { LogoAxis } from '@axis/xyz.logos.axis';
import css from './ErrorState.module.css';

function ErrorState() {
  return (
    <div className={css.errorMain}>
      <LogoAxis className={css.errorLogo} />
      <h1 className={css.errorTitle}>Something went wrong</h1>
      <h2 className={css.errorDescription}>
        There was an unexpected error in the dock.
        <br />
        If the error persists report it to the Axis team.
      </h2>
    </div>
  );
}

export default ErrorState;
