import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import css from './NotesDossier.module.css';
import CREATE_NOTE from '../../../../../queries/createNote';
import UPDATE_NOTE from '../../../../../queries/updateNote';
import DELETE_NOTE from '../../../../../queries/deleteNote';
import GET_ENTITY_BY_ID from '../../../../../queries/dossierEntity';
import GET_ALL_NOTES from '../../../../../queries/getAllNotes';
import GET_NOTES_FOR_ENTITY from '../../../../../queries/getNotesForEntity';
import arrowBack from '../../../../../icons/arrow_back.svg';
import DefaultButton from '../../../../../components/DefaultButton/DefaultButton';
import DossierNotes from '../../../DossierNotes/DossierNotes';
import notify from '../../../../../lib/notify';
import { NoteType } from '../../../../hooks/useNotes';

function NotesDossier({ entity, onCancel }) {
  const getNotes = useQuery(GET_NOTES_FOR_ENTITY, {
    variables: { entityId: entity.id },
  });

  const updateNotesCount = (cache, increment) => {
    const data = cache.readQuery({
      query: GET_ENTITY_BY_ID,
      variables: { id: entity.id },
    });
    cache.writeQuery({
      query: GET_ENTITY_BY_ID,
      variables: { id: entity.id },
      data: {
        getDossierEntity: {
          ...data.getDossierEntity,
          notesCount: data.getDossierEntity.notesCount + increment,
        },
      },
    });
  };

  const [insertNote, createNoteDetails] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_ENTITY],
    onError: (error) => notify.error(error),
    update: (cache) => updateNotesCount(cache, 1),
  });

  const [updateNoteMutation, updateNoteDetails] = useMutation(UPDATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_ENTITY],
    onError: (error) => notify.error(error),
  });

  const [deleteNoteMutation, deleteNoteDetails] = useMutation(DELETE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_ENTITY],
    onError: (error) => notify.error(error),
    update: (cache) => updateNotesCount(cache, -1),
  });

  const saveNote = async (note) => {
    await insertNote({
      variables: {
        targetId: entity.id,
        note,
        noteType: NoteType.entityNotes,
      },
    });
  };

  const onEditNote = async (note) => {
    await updateNoteMutation({
      variables: {
        noteId: note.id,
        note: note.note,
        noteType: NoteType.entityNotes,
      },
    });
  };

  const onDeleteNote = async (note) => {
    await deleteNoteMutation({
      variables: {
        noteId: note.id,
        noteType: NoteType.entityNotes,
      },
    });
  };

  const notes = getNotes.data?.getNotesForEntity.entityNotes ?? [];
  const isSaving = createNoteDetails.loading;

  return (
    <div className={css.bodyItems}>
      <DefaultButton
        label={`Back to ${entity.name}`}
        onClick={onCancel}
        icon={<img src={arrowBack} alt="back" />}
        className={css.addNoteBackButton}
      />

      <DossierNotes
        notes={notes}
        saveNote={saveNote}
        isSavingNote={isSaving}
        isUpdatingNote={updateNoteDetails.loading}
        onEditNote={onEditNote}
        onDeleteNote={onDeleteNote}
        isDeletingNote={deleteNoteDetails.loading}
      />
    </div>
  );
}

export default NotesDossier;
