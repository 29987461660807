import React from 'react';

function Event(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2487 2H2.7487C1.96736 2 1.33203 2.598 1.33203 3.33333V12.6667C1.33203 13.402 1.96736 14 2.7487 14H13.2487C14.03 14 14.6654 13.402 14.6654 12.6667V3.33333C14.6654 2.598 14.03 2 13.2487 2ZM13.2487 12.6667H2.7487C2.7107 12.6667 2.6847 12.656 2.67336 12.656C2.6687 12.656 2.66603 12.6573 2.66536 12.6613L2.65736 3.364C2.66203 3.35733 2.69203 3.33333 2.7487 3.33333H13.2487C13.3014 3.334 13.33 3.352 13.332 3.33867L13.34 12.636C13.3354 12.6427 13.3054 12.6667 13.2487 12.6667Z"
        fill="currentColor"
      />
      <path
        d="M4 4.66675H8V8.66675H4V4.66675ZM8.66667 10.0001H4V11.3334H12V10.0001H9.33333H8.66667ZM9.33333 7.33341H12V8.66675H9.33333V7.33341ZM9.33333 4.66675H12V6.00008H9.33333V4.66675Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Event;
