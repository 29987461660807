import * as React from 'react';

function Bookmark(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_7089_3707)">
        <mask
          id="a"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={16}
          height={16}
        >
          <path fill="#D9D9D9" d="M0 0H16V16H0z" />
        </mask>
        <g mask="url(#a)">
          <path
            d="M3.334 14V3.333c0-.366.13-.68.392-.941C3.986 2.13 4.3 2 4.667 2h6.667c.367 0 .68.13.942.392.26.26.391.575.391.941V14l-4.666-2-4.667 2z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7089_3707">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Bookmark;
