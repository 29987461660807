import React, { useState } from 'react';
import css from './Body.module.css';
import BookmarkButton from './BookmarkButton/BookMarkButton';
import ConnectButton from './ConnectButton/ConnectButton';
import { Countries, Industries } from './Tags/Tags';
import AiBadge from '../../../AiBadge/AiBadge';
import ChevronDown from '../../../../../icons/ChevronDown';
import Activity from './Activity/Activity';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import AlterName from './AlterName/AlterName';
import Roles from './Roles/Roles';
import Education from './Education/Education';
import AxisUserGuard from '../../../AxisUserGuard/AxisUserGuard';
import NotesOutline from '../../../../../icons/NotesOutline';

function Body({
  entity,
  toggleBookmark,
  toggleUserConnection,
  size,
  isLoading,
  showNotesDossier,
}) {
  const [showMore, setShowMore] = useState(false);

  const showRoleEducationLine = Boolean(
    entity && (entity.roles?.length || entity.educations?.length),
  );

  const showInfo = Boolean(
    entity && (entity.lifespan || entity.alternateNames?.length),
  );

  if (isLoading) return null;

  if (!entity.inSubscription) return <OutOfSubscription />;

  const notesLabel = entity.notesCount === 1 ? 'Note' : 'Notes';
  const addOrShowLabel = entity.notesCount > 0 ? 'Show' : 'Add';

  return (
    <div className={css.main}>
      <div className={css.actions}>
        <BookmarkButton entity={entity} toggleBookmark={toggleBookmark} />

        <ConnectButton
          entity={entity}
          toggleConnection={toggleUserConnection}
        />
      </div>

      <div className={css.notes}>
        <div className={css.notesCount}>
          {entity.notesCount} {notesLabel}
        </div>

        <button type="button" className={css.button} onClick={showNotesDossier}>
          <NotesOutline />
          {addOrShowLabel} {notesLabel}
        </button>
      </div>

      <div className={css.tags}>
        <Countries
          primaryTopicId={entity.primaryTopicId}
          countries={entity.countries}
          size={size}
        />
        <Industries industries={entity.industries} size={size} />
      </div>

      <AxisUserGuard>
        {entity.bio && (
          <div className={css.bio}>
            <div className={css.bioTitle}>
              Biography <AiBadge text="  Beta" />
            </div>
            <p className={css.bioContent} data-expanded={showMore}>
              {entity.bio}
            </p>

            <button
              type="button"
              onClick={() => setShowMore(!showMore)}
              className={css.showMore}
              data-expanded={showMore}
            >
              {showMore ? 'Show Less' : 'Show More'}
              <ChevronDown className={css.chevronDown} />
            </button>
          </div>
        )}
      </AxisUserGuard>

      <Activity entity={entity} />

      <div className={css.cards}>
        {showInfo && <PersonalInformation entity={entity} />}
        {showInfo && <AlterName entity={entity} />}
        {showRoleEducationLine && <Roles entity={entity} />}
        {showRoleEducationLine && <Education entity={entity} />}
      </div>
    </div>
  );
}

export default Body;

function OutOfSubscription() {
  return (
    <p className={css.outsideSubscription}>
      This content is outside your subscription. Please
      <a href="mailto:contact@axis.xyz"> contact Sales</a> to gain access.
    </p>
  );
}
