import React from 'react';
import useCardPagination from '../DossierCard/useCardPagination';
import DossierCard from '../DossierCard/DossierCard';

function AlterName({ entity, className }) {
  const { state, pagination } = useCardPagination(entity.alternateNames);

  if (!state?.data) return null;

  return (
    <DossierCard
      title="Also Known As"
      className={className}
      data-cy="altername-facet"
      pagination={pagination}
    >
      {state.data}
    </DossierCard>
  );
}

export default AlterName;
