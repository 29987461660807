import React from 'react';

function Regulation() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2885_3103"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2885_3103)">
        <path
          d="M1.33203 13.9999V12.6666H7.33203V5.21659C7.04314 5.11659 6.79314 4.96103 6.58203 4.74992C6.37092 4.53881 6.21536 4.28881 6.11537 3.99992H3.9987L5.9987 8.66659C5.9987 9.22214 5.77092 9.69436 5.31536 10.0833C4.85981 10.4721 4.30981 10.6666 3.66536 10.6666C3.02092 10.6666 2.47092 10.4721 2.01536 10.0833C1.55981 9.69436 1.33203 9.22214 1.33203 8.66659L3.33203 3.99992H1.9987V2.66659H6.11537C6.2487 2.2777 6.48759 1.95814 6.83203 1.70792C7.17648 1.45814 7.56536 1.33325 7.9987 1.33325C8.43203 1.33325 8.82092 1.45814 9.16536 1.70792C9.50981 1.95814 9.7487 2.2777 9.88203 2.66659H13.9987V3.99992H12.6654L14.6654 8.66659C14.6654 9.22214 14.4376 9.69436 13.982 10.0833C13.5265 10.4721 12.9765 10.6666 12.332 10.6666C11.6876 10.6666 11.1376 10.4721 10.682 10.0833C10.2265 9.69436 9.9987 9.22214 9.9987 8.66659L11.9987 3.99992H9.88203C9.78203 4.28881 9.62648 4.53881 9.41537 4.74992C9.20425 4.96103 8.95425 5.11659 8.66536 5.21659V12.6666H14.6654V13.9999H1.33203ZM11.082 8.66659H13.582L12.332 5.76659L11.082 8.66659ZM2.41536 8.66659H4.91536L3.66536 5.76659L2.41536 8.66659ZM7.9987 3.99992C8.18759 3.99992 8.34603 3.93614 8.47403 3.80859C8.60159 3.68059 8.66536 3.52214 8.66536 3.33325C8.66536 3.14436 8.60159 2.98592 8.47403 2.85792C8.34603 2.73036 8.18759 2.66659 7.9987 2.66659C7.80981 2.66659 7.65159 2.73036 7.52403 2.85792C7.39603 2.98592 7.33203 3.14436 7.33203 3.33325C7.33203 3.52214 7.39603 3.68059 7.52403 3.80859C7.65159 3.93614 7.80981 3.99992 7.9987 3.99992Z"
          fill="#42474D"
        />
      </g>
    </svg>
  );
}

export default Regulation;
