import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import css from './Bookmarks.module.css';
import PanelHeader from '../PanelHeader/PanelHeader';
import useBookmarks from '../../../hooks/useBookmarks';
import BookmarkCard from './BookmarkCard/Bookmark';
import { modalStyles } from '../../../shared/modal';
import RemoveBookmark from './RemoveBookmark/RemoveBookmark';
import notify from '../../../../lib/notify';

const typeModal = {
  REMMOVE: 'REMOVE',
};

function Bookmarks() {
  const [openedModal, setOpenedModal] = useState(null);
  const { bookmarks, loading, refetch, removeBookmark } = useBookmarks();

  const onSearch = (searchTerm) => {
    refetch({ searchTerm });
  };

  const onRemoveBookmark = (bookmark) => {
    setOpenedModal({ modal: typeModal.REMMOVE, context: bookmark });
  };

  const onRemoveConfirmed = async (bookmark) => {
    try {
      setOpenedModal(null);
      await removeBookmark({ variables: { id: bookmark.id, type: 'ENTITY' } });
      refetch();
      notify.success('Bookmark removed!');
    } catch (errors) {
      notify.error(errors[0].message);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className={css.main}>
      <PanelHeader
        count={bookmarks.length}
        onSearch={onSearch}
        title="Bookmarks"
      />
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {bookmarks.map((bookmark) => (
            <BookmarkCard
              key={bookmark.id}
              entity={bookmark}
              onRemoveBookmark={onRemoveBookmark}
            />
          ))}
        </div>
      )}

      <Modal
        isOpen={Boolean(openedModal)}
        style={modalStyles}
        contentLabel="Remove bookmark"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenedModal(null)}
      >
        {openedModal?.modal === typeModal.REMMOVE && (
          <RemoveBookmark
            entity={openedModal.context}
            onClose={() => setOpenedModal(null)}
            onRemove={() => onRemoveConfirmed(openedModal.context)}
          />
        )}
      </Modal>
    </div>
  );
}

export default Bookmarks;

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);
