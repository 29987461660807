import React from 'react';

import css from './DossierCard.module.css';
import ChevronLeft from '../../../../../../icons/ChevronLeft';
import ChevronRight from '../../../../../../icons/ChevronRight';

function DossierCard({ title, children, className, pagination, ...props }) {
  return (
    <div className={`${css.card} ${className}`} {...props}>
      <div className={css.cardContent}>
        <div className={css.header} data-cy="dossier-card-title">
          <div className={css.cardTitle}>{title}</div>
          {pagination && pagination.totalPages > 1 && (
            <div className={css.pagination}>
              <div className={css.buttons}>
                <NavigationButton
                  dataCy="dossier-card-page-previous"
                  onClick={pagination.onPreviousPage}
                  disabled={pagination.currentIndex === 0}
                >
                  <ChevronLeft />
                </NavigationButton>
                <div className={css.pageInfo} data-cy="dossier-card-page-index">
                  {pagination.currentIndex + 1} of {pagination.totalPages}
                </div>
                <NavigationButton
                  dataCy="dossier-card-page-next"
                  onClick={pagination.onNextPage}
                  disabled={
                    pagination.currentIndex === pagination.totalPages - 1
                  }
                >
                  <ChevronRight />
                </NavigationButton>
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default DossierCard;

/** Make it an isolate component if necessary */
function NavigationButton({ disabled, onClick, children, dataCy }) {
  return (
    <button
      type="button"
      data-cy={dataCy}
      onClick={onClick}
      data-disabled={disabled}
      className={css.navigationButton}
    >
      {children}
    </button>
  );
}

export function DossierCardItem({ title, content, ...props }) {
  return (
    <div className={css.itemContainer} {...props}>
      <div className={css.itemTitle}>{title}</div>
      <div className={css.itemContent}>{content}</div>
    </div>
  );
}
