import React, { useMemo } from 'react';

import css from './EventCard.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import useNavigation from '../../../hooks/useNavigation';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import NotesOutline from '../../../../icons/NotesOutline';
import Score from '../../Score/Score';
import AxisUserGuard from '../../AxisUserGuard/AxisUserGuard';
import { calcImportanceScore } from '../../../shared/events';
import {
  sortCountriesByRelevance,
  sortIndustriesByRelevance,
} from '../../../../utils/tags';

const Languages = new Intl.DisplayNames(['en'], { type: 'language' });

/**
 * @param {Object} event
 * @param {string} size 'normal' or 'expanded'
 */
function EventCard({ event, size }) {
  const { toggleEventDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(event.magnitude, event.sentiment),
    [event],
  );

  const industries = useMemo(
    () => sortIndustriesByRelevance(event.industries),
    [event],
  );

  const countries = useMemo(
    () => sortCountriesByRelevance(event.countries),
    [event],
  );

  return (
    <BaseCard.Root data-size={size} className={css.root}>
      <BaseCard.Row>
        <BaseCard.LeftColumn>
          <BaseCard.DateRow
            date={event.date}
            before={<Score score={score} />}
          />

          <BaseCard.Title
            className={css.title}
            onClick={() => toggleEventDossier(event.id)}
          >
            {event.title}
          </BaseCard.Title>

          <BaseCard.Summary summary={event.summary} bullets={event.bullets} />
        </BaseCard.LeftColumn>

        <BaseCard.RightColumn>
          <BaseCard.InfoColumn title="Actors">
            <div className={css.entities}>
              {event.entities.map((entity) => (
                <EntityPill
                  key={entity.id}
                  id={entity.id}
                  image={entity.image}
                  name={entity.name}
                  shortName={entity.shortestName}
                  tooltip={entity.tooltip}
                  type={entity.type}
                  isConnected={entity.isConnected}
                  inSubscription={entity.inSubscription}
                  autoOpenDossier
                  size="small"
                />
              ))}
            </div>
          </BaseCard.InfoColumn>

          {event.industries?.length > 0 && (
            <BaseCard.InfoColumn title="Industries">
              <BaseCard.BriefPillList isGeography={false} briefs={industries} />
            </BaseCard.InfoColumn>
          )}

          {event.countries?.length > 0 && (
            <BaseCard.InfoColumn title="Countries">
              <BaseCard.BriefPillList isGeography briefs={countries} />
            </BaseCard.InfoColumn>
          )}

          <AxisUserGuard>
            {!!event.language && event.language !== 'en' && (
              <BaseCard.InfoColumn title="Language">
                {Languages.of(event.language)}
              </BaseCard.InfoColumn>
            )}
          </AxisUserGuard>
        </BaseCard.RightColumn>
      </BaseCard.Row>
      <BaseCard.BottomRow className={css.footer}>
        <div className={css.buttons}>
          <button
            type="button"
            className={css.button}
            onClick={() => toggleEventDossier(event.id, false, true)}
          >
            <NotesOutline />
            Add Note
            {event.notesCount > 0 && (
              <div className={css.count}>{event.notesCount}</div>
            )}
          </button>
          <div className={css.divider} />

          {event.quotes.length > 0 && (
            <button
              type="button"
              className={css.button}
              onClick={() => toggleEventDossier(event.id, true)}
            >
              Quotes <div className={css.count}>{event.quotes.length}</div>
            </button>
          )}
        </div>
        <BaseCard.CardSources sources={event.sources} />
      </BaseCard.BottomRow>
    </BaseCard.Root>
  );
}

export default EventCard;
