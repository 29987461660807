import React, { useState } from 'react';

import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import css from './ConnectButton.module.css';
import Button from '../../../../Buttom/Buttom';
import notify from '../../../../../../lib/notify';
import AddConnectionModal from './AddConnectionModal';
import { modalStyles } from '../../../../../shared/modal';
import Connection from '../../../../../../icons/Connection';
import AddConnection from '../../../../../../icons/AddConnection';
import GET_CONTACTS from '../../../../../../queries/getUserConnections';
import TOGGLE_CONNECTION from '../../../../../../queries/toggleUserConnection';
import RemoveConnection from '../../../../LeftPanel/Connections/RemoveConnection/RemoveConnection';

const connectionModalTypes = {
  CONNECT: 'CONNECT',
  REMOVE: 'REMOVE',
};

function ConnectButton({ entity, toggleConnection }) {
  const [connectionModalOpen, setConnectionModalOpen] = useState(undefined);

  const [toggleUserConnection] = useMutation(TOGGLE_CONNECTION, {
    refetchQueries: [GET_CONTACTS],
  });

  const onClose = () => {
    setConnectionModalOpen(null);
  };

  const onConfirmChanges = async (value) => {
    try {
      await toggleUserConnection({
        variables: {
          entityId: entity.id,
          flag: value.flag,
          note: value.note,
        },
      });
      toggleConnection(value.flag);
    } catch (error) {
      notify.error('Error');
    } finally {
      onClose();
    }
  };

  const handleEntityConnectionClick = async () => {
    const type = entity.isConnected
      ? connectionModalTypes.REMOVE
      : connectionModalTypes.CONNECT;
    setConnectionModalOpen(type);
  };

  const onRemoveConnection = () => {
    onConfirmChanges({ flag: false, note: '' });
  };

  if (entity.subType !== 'Person') return null;

  const type = entity.isConnected ? 'success' : 'secondary';
  const Icon = entity.isConnected ? Connection : AddConnection;
  const identifier = entity.isConnected
    ? 'dossier-remove-connection'
    : 'dossier-add-connection';

  return (
    <>
      <Button
        type={type}
        onClick={handleEntityConnectionClick}
        data-cy={identifier}
      >
        <Icon className={css.icon} />
        Contact
      </Button>

      <Modal
        isOpen={connectionModalOpen === connectionModalTypes.CONNECT}
        style={modalStyles}
        contentLabel="Add connection"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={onClose}
      >
        <AddConnectionModal
          onClose={onClose}
          onConfirmChanges={onConfirmChanges}
        />
      </Modal>

      <Modal
        isOpen={connectionModalOpen === connectionModalTypes.REMOVE}
        style={modalStyles}
        contentLabel="Remove personal connection"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={onClose}
      >
        <RemoveConnection
          entity={entity}
          note={entity.connectionNote}
          onRemove={onRemoveConnection}
          onClose={onClose}
        />
      </Modal>

      {/* <Modal
        isOpen={isModalOpen}
        style={modalStyles}
        contentLabel="Add Connection"
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsModalOpen(false)}
      >
        <AddConnectionModal
          onClose={() => setIsModalOpen(false)}
          onConfirmChanges={onConfirmChanges}
        />
      </Modal> */}
    </>
  );
}

export default ConnectButton;
