import React from 'react';

function Close(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.267 12.667l-.934-.934L7.067 8 3.333 4.267l.934-.934L8 7.067l3.733-3.734.934.934L8.933 8l3.734 3.733-.934.934L8 8.933l-3.733 3.734z"
        fill="#fff"
      />
    </svg>
  );
}

export default Close;
