import React from 'react';

import css from './Roles.module.css';
import useCardPagination from '../DossierCard/useCardPagination';
import DossierCard, { DossierCardItem } from '../DossierCard/DossierCard';
import {
  getRoleDetails,
  getDateValueForRoles,
} from '../../../../../../lib/facets/role';
import EntityPill from '../../../../../../components/EntityPill/EntityPill';

function Roles({ entity, className }) {
  const roles = getRoleDetails(entity);
  const { state, pagination } = useCardPagination(roles?.roles || []);

  if (!state.data) return null;

  const dates = getDateValueForRoles({
    start: state.data.startDate,
    end: state.data.endDate,
  });

  const { employerEntity } = state.data;

  const content = employerEntity ? (
    <div className={css.pillContainer}>
      <EntityPill
        size="small"
        autoOpenDossier
        id={employerEntity.id}
        type={employerEntity.type}
        name={employerEntity.name}
        image={employerEntity.image}
        shortName={employerEntity.shortestName}
        isConnected={employerEntity.isConnected}
        inSubscription={employerEntity.inSubscription}
      />
    </div>
  ) : (
    state.data.org
  );
  return (
    <DossierCard
      title="Roles"
      data-cy="roles-facet"
      className={className}
      pagination={pagination}
    >
      <DossierCardItem
        title="Title"
        data-cy="roles-title"
        content={state.data.position}
      />
      <DossierCardItem title="Org" data-cy="roles-org" content={content} />
      {dates && (
        <DossierCardItem title="Date" data-cy="roles-date" content={dates} />
      )}
    </DossierCard>
  );
}

export default Roles;
