import React from 'react';

function Quote(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.37 15.425l2.449-4.266a1.275 1.275 0 01-.413.138c-.157.026-.314.04-.471.04-.954 0-1.756-.332-2.406-.994a3.286 3.286 0 01-.975-2.388c0-.954.325-1.755.975-2.405.65-.65 1.452-.975 2.406-.975.93 0 1.725.325 2.387.975.662.65.993 1.45.993 2.399 0 .33-.04.639-.12.925-.081.287-.196.557-.344.811l-3.31 5.74H4.37zm8.963 0l2.449-4.266a1.276 1.276 0 01-.413.138c-.157.026-.314.04-.472.04-.954 0-1.756-.332-2.405-.994a3.286 3.286 0 01-.975-2.388c0-.967.325-1.772.975-2.415.65-.644 1.451-.965 2.405-.965.93 0 1.726.325 2.388.975.662.65.993 1.45.993 2.399 0 .33-.04.639-.12.925-.081.287-.196.557-.344.811l-3.31 5.74h-1.171z"
        fill="#42474D"
      />
      <path
        d="M5.935 5.715c.622 0 1.15.218 1.587.653.435.436.653.965.653 1.587 0 .623-.218 1.152-.653 1.588a2.161 2.161 0 01-1.587.653 2.162 2.162 0 01-1.588-.653 2.161 2.161 0 01-.653-1.588c0-.622.217-1.151.653-1.587m10.55-.653c.623 0 1.152.218 1.588.653.435.436.653.965.653 1.587a2.16 2.16 0 01-.654 1.588 2.161 2.161 0 01-1.587.653 2.161 2.161 0 01-1.587-.653 2.16 2.16 0 01-.653-1.588c0-.622.217-1.151.653-1.587m-8.94 9.057l2.449-4.266a1.275 1.275 0 01-.413.138c-.157.026-.314.04-.471.04-.954 0-1.756-.332-2.406-.994a3.286 3.286 0 01-.975-2.388c0-.954.325-1.755.975-2.405.65-.65 1.452-.975 2.406-.975.93 0 1.725.325 2.387.975.662.65.993 1.45.993 2.399 0 .33-.04.639-.12.925-.081.287-.196.557-.344.811l-3.31 5.74H4.37zm8.963 0l2.449-4.266a1.276 1.276 0 01-.413.138c-.157.026-.314.04-.472.04-.954 0-1.756-.332-2.405-.994a3.286 3.286 0 01-.975-2.388c0-.967.325-1.772.975-2.415.65-.644 1.451-.965 2.405-.965.93 0 1.726.325 2.388.975.662.65.993 1.45.993 2.399 0 .33-.04.639-.12.925-.081.287-.196.557-.344.811l-3.31 5.74h-1.171z"
        stroke="#42474D"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default Quote;
