import React from 'react';

import Sparkle from '../../../icons/Smart';
import css from './AiBadge.module.css';

function AiBadge({ text }) {
  return (
    <span className={css.main}>
      <Sparkle />
      AI
      {text}
    </span>
  );
}

export default AiBadge;
