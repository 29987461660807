import React from 'react';

import css from './Header.module.css';
import Close from '../../../../../icons/Close';
import Online from '../../../../../dossier/facets/Online/Online';
import { useRouteInformation } from '../../../../../contexts/RouteInformationContext';

function Header({ isLoading, entity }) {
  const router = useRouteInformation();

  if (isLoading) {
    return null;
  }

  return (
    <div
      className={css.main}
      style={{
        backgroundImage: `url(${entity.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <button
        type="button"
        className={css.closeButton}
        onClick={router.closeDossier}
      >
        <Close />
      </button>

      <div className={css.shaddow}>
        <div className={css.name}>{entity.name}</div>
        <div className={css.roleTitle}>{entity.primaryRoleTitle}</div>
        <Online socials={entity.onlinePresence} />
      </div>
    </div>
  );
}

export default Header;
