import { useState } from 'react';

import { useQuery } from '@apollo/client';
import GET_SEARCH_COUNTS from '../../../queries/searchCounts';
import { getRegulationKey } from '../../../v2/components/Filter';

const formatCounts = (entitiesResult, facetResult) => {
  const updatedCounts = {};

  entitiesResult.categories.forEach((item) => {
    updatedCounts[item.type] = item.total;
  });

  updatedCounts.events = facetResult.events.total;
  updatedCounts.regulations = facetResult.regulations.total;

  facetResult.regulationTypesCount.forEach((item) => {
    updatedCounts[getRegulationKey(item.type)] = item.total;
  });

  facetResult.eventTypesCount.forEach((item) => {
    updatedCounts[item.type] = item.total;
  });

  return updatedCounts;
};

function useSearchCounts(fetchPolicy = 'no-cache') {
  const [counts, setCounts] = useState(null);
  const [variables, setVariables] = useState();

  const { loading } = useQuery(GET_SEARCH_COUNTS, {
    variables,
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        const updatedCounts = formatCounts(
          data.searchEntitiesCount,
          data.searchEventsAndRegulationsCount,
        );
        setCounts(updatedCounts);
      }
    },
  });

  const fetchCounts = ({
    search,
    industries,
    countries,
    entities,
    extraFilter,
    dates,
    filterActorTypes,
  }) => {
    const vars = {
      name: search,
      filterIndustries: industries,
      topics: countries,
      filterEntities: entities,
      filterActorTypes: ['actor', ...filterActorTypes],
      id: window.crypto.randomUUID(),
      bookmarked: extraFilter === 'bookmarks',
      connected: extraFilter === 'connections',
      notes: extraFilter === 'notes',
      compliance: extraFilter === 'compliant',
    };

    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      vars.startDate = startDate;
      vars.endDate = endDate;
    }

    setVariables(vars);
  };

  return {
    counts,
    fetchCounts,
    isCountLoading: loading,
  };
}

export default useSearchCounts;
