import React from 'react';

import { Loading } from '@axis/xyz.app.loading';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../icons/ArrowLeft';
import css from './DossierHeader.module.css';
import CloseDossierButton from './components/CloseDossierButton/CloseDossierButton';

const DossierHeader = ({ isLoading, children, border, sticky }) => {
  const history = useHistory();
  const { state } = history.location;

  if (isLoading) {
    return (
      <div data-cy="loading" className={css.loading}>
        <Loading className={css.spinner} />
      </div>
    );
  }

  return (
    <div data-cy="dossier-header" className={css.header} data-sticky={sticky}>
      <div
        className={css.controls}
        data-border={border}
        data-sticky={sticky}
        data-cy="dossier-controls"
      >
        <div />

        {state?.back && (
          <button type="button" className={css.goBack} onClick={history.goBack}>
            <ArrowLeft />
            {state.back}
          </button>
        )}

        <CloseDossierButton />
      </div>
      {children}
    </div>
  );
};

export default DossierHeader;
