import React from 'react';

import css from './LeftPanel.module.css';
import useNavigation from '../../hooks/useNavigation';
import SavedSearches from './SavedSearches/SavedSearches';
import CompliantRegulations from './CompliantRegulations/CompliantRegulations';
import Connections from './Connections/Connections';
import Bookmarks from './Bookmarks/Bookmarks';
import Notes from './Notes/Notes';
import Notifications from './Notifications/Notifications';
import AbstractErrorBundary from '../AbstractErrorBundary/AbstractErrorBundary';
import ErrorState from './ErrorState/ErrorState';

const panelComponentMap = {
  'saved-searches': <SavedSearches />,
  compliance: <CompliantRegulations />,
  connections: <Connections />,
  bookmarks: <Bookmarks />,
  notes: <Notes />,
  notifications: <Notifications />,
};

function LeftPanel() {
  const { params } = useNavigation();
  const panel = params.get('leftPanel');

  const Component = panelComponentMap[panel] ?? null;

  return (
    <div className={css.main} data-open={Boolean(panel)}>
      <AbstractErrorBundary fallback={<ErrorState />}>
        {Component}
      </AbstractErrorBundary>
    </div>
  );
}

export default LeftPanel;
